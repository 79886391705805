import { InputText } from "./textBoxStyles/textBox.style";
import PropTypes from "prop-types";

const TextBox = ({
  placeHolder,
  width,
  onChange,
  value,
  onClick,
  onBlur,
  icon,
  height,
  disabled,
  keyPress,
  rounded,
  centerText,
  password,
  type,
  defaultValue,
  key,
  id,
  name,
  marginRight,
  marginLeft,
  colors,
  fullWidth,
}) => {
  return (
    <InputText
      fullWidth={fullWidth}
      id={id}
      name={name}
      placeholder={placeHolder}
      size="small"
      width={width}
      height={height}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      disabled={disabled}
      onKeyPress={keyPress}
      InputProps={icon && { endAdornment: icon }}
      inputProps={{ style: { textAlign: `${centerText && "center"}` } }}
      rounded={rounded}
      defaultValue={defaultValue}
      marginRight={marginRight}
      marginLeft={marginLeft}
      colors={colors}
      type={type}
      key={key}
    />
  );
};

TextBox.propTypes = {
  fullWidth: PropTypes.bool,
  placeHolder: PropTypes.string,
  width: PropTypes.number,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.node,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  keyPress: PropTypes.func,
  rounded: PropTypes.bool,
  centerText: PropTypes.bool,
  password: PropTypes.bool,
  defaultValue: PropTypes.any,
  key: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  colors: PropTypes.string,
};

export default TextBox;
