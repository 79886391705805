import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useSideBar } from "../../Context/SideBarProvider";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";
import { useRtl } from "../../Context/RtlProvider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../Context/SearchProvider";
import StrinLocalized from "../../Language/language.lang";
import { getAll } from "../../Utils/getAll";
import strings from "../../Language/language.lang";
import axiosInstance from "../../HelperAxios/axios";
import { useTimeZoneMap } from "../../Context/TimeZone";
import { API_URL_PHOTO } from "../../HelperAxios/const";

const Header = () => {
  const { toggle } = useSideBar();
  const currentUser = useAuthUser();
  const navigate = useNavigate();
  const [languaes, setLanguages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getTimeZones } = useTimeZoneMap();
  const [user, setUser] = useState();
  const open = Boolean(anchorEl);
  const { rtl, changeToRtl, changeToLtr } = useRtl();
  const { search, setSearch } = useSearch();
  useEffect(() => {
    getAll(setLanguages, `language/select-list-active?platform=Portal`);
    getAll(setUser, "user/get-user-profile");
    getTimeZones();
  }, []);
  const langFile = localStorage.getItem("langfile");
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    if (langFile) {
      axiosInstance
        .get(`public/${langFile}`)
        .then((file) => {
          if (file) {
            const fileObj = file.data;
            const obj = { [lang]: fileObj };

            strings.setContent(obj);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [langFile]);

  // console.log("lang --------------", localStorage.getItem("langfile"));
  // const persian = "1707308692069.json"
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeToRtl = (item) => {
    if (item.file) {
      changeToRtl();

      localStorage.setItem("langfile", item.file);
      axiosInstance
        .get(`public/${item.file}`)
        .then((file) => {
          if (file) {
            const fileObj = file.data;
            const obj = { [item.alias]: fileObj };
            console.log(obj);
            strings.setContent(obj);
          }
        })
        .catch((err) => console.log(err));

      strings.setLanguage(item.alias);

      localStorage.setItem("lang", item.alias);
      handleClose();
      location.reload();
    }
  };

  const handleChangeToLtr = (item) => {
    if (item.file) {
      changeToLtr();
      localStorage.setItem("langfile", item.file);
      axiosInstance
        .get(`public/${item.file}`)
        .then((file) => {
          if (file) {
            const fileObj = file.data;
            const obj = { [item.alias]: fileObj };

            strings.setContent(obj);
          }
        })
        .catch((err) => console.log(err));

      strings.setLanguage(item.alias);

      localStorage.setItem("lang", item.alias);
      handleClose();
      location.reload();
    }
  };

  return (
    <Container
      maxWidth="xlg"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
      }}
    >
      <Stack
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row-reverse" alignItems="center">
          {currentUser && (
            <IconButton onClick={() => navigate("my-profile")}>
              <Avatar src={`${API_URL_PHOTO}${user?.picture}`} />
            </IconButton>
          )}

          <div>
            <IconButton id="language-menu" onClick={handleClick}>
              <LanguageOutlinedIcon />
            </IconButton>
            {languaes?.length > 0 && (
              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {languaes.map(
                  (item) =>
                    item.file && (
                      <MenuItem
                        key={item.id}
                        onClick={
                          item.RTL == "yes"
                            ? handleChangeToRtl.bind(null, item)
                            : handleChangeToLtr.bind(null, item)
                        }
                      >
                        {item?.name}
                      </MenuItem>
                    )
                )}
              </Menu>
            )}
          </div>
          <IconButton onClick={() => navigate("notifications")}>
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={toggle}>
            <DehazeIcon />
          </IconButton>
          <TextField
            sx={{ marginLeft: "20px" }}
            size="small"
            id="input-with-icon-textfield"
            placeholder={StrinLocalized.Search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default Header;
