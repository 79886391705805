import React from "react";

const DashboardIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 15H15V7H7V15ZM9 9H13V13H9V9Z" />
        <path d="M17 7V15H25V7H17ZM23 13H19V9H23V13Z" />
        <path d="M7 25H15V17H7V25ZM9 19H13V23H9V19Z" />
        <path d="M22 17H20V20H17V22H20V25H22V22H25V20H22V17Z" />
      </svg>
    </div>
  );
};

export default DashboardIcon;
