import { Stack, Typography, Container, Popper, Fade, Box } from "@mui/material";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { useRtl } from "../../Context/RtlProvider";

const PageHeader = ({ title, subTitle, info }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { rtl } = useRtl();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container
      maxWidth="xlg"
      sx={{
        borderBottom: "1px solid #D6D5D5",
        paddingY: "20px",
      }}
    >
      <Stack direction="column">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h5" color="#505050" fontWeight={500}>
            {title}
          </Typography>
          {info ? (
            <>
              <InfoIcon
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                fontSize="small"
                sx={{ color: "#505050" }}
              />
              <Popper
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                  maxWidth: "1000px",
                  zIndex: 1000,
                }}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Fade in={open} timeout={350}>
                  <Typography
                    sx={{
                      p: 1,
                      whiteSpace: "pre-wrap",
                      backgroundColor: "whitesmoke",
                      borderRadius: 1,
                      boxShadow: 2,
                    }}
                  >
                    {info}
                  </Typography>
                </Fade>
              </Popper>
            </>
          ) : null}
        </Stack>
        <Typography variant="subtitle1" color="#505050" fontWeight={400}>
          {subTitle}
        </Typography>
      </Stack>
    </Container>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  info: PropTypes.string,
};

export default PageHeader;
