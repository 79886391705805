import React from "react";

const ProjectsIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M25 16V14H23V11L20 8L18 10L16 8L14 10L12 8L9 11V14H7V16H9V18H7V20H9V24H23V20H25V18H23V16H25ZM20 10.83L21 11.83V14H19V11.83L20 10.83ZM16 10.83L17 11.83V14H15V11.83L16 10.83ZM15 18V16H17V18H15ZM17 20V22H15V20H17ZM11 11.83L12 10.83L13 11.83V14H11V11.83ZM11 16H13V18H11V16ZM11 20H13V22H11V20ZM21 22H19V20H21V22ZM21 18H19V16H21V18Z" />
      </svg>
    </div>
  );
};

export default ProjectsIcon;
