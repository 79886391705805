import React from "react";

const NegotiatesIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13 17.5C15.21 17.5 17 15.71 17 13.5C17 11.29 15.21 9.5 13 9.5C10.79 9.5 9 11.29 9 13.5C9 15.71 10.79 17.5 13 17.5ZM13 11.5C14.1 11.5 15 12.4 15 13.5C15 14.6 14.1 15.5 13 15.5C11.9 15.5 11 14.6 11 13.5C11 12.4 11.9 11.5 13 11.5ZM13 19.5C10.33 19.5 5 20.84 5 23.5V25.5H21V23.5C21 20.84 15.67 19.5 13 19.5ZM7 23.5C7.22 22.78 10.31 21.5 13 21.5C15.7 21.5 18.8 22.79 19 23.5H7ZM19.08 11.55C19.92 12.73 19.92 14.26 19.08 15.44L20.76 17.13C22.78 15.11 22.78 12.06 20.76 9.86L19.08 11.55ZM24.07 6.5L22.44 8.13C25.21 11.15 25.21 15.69 22.44 18.87L24.07 20.5C27.97 16.61 27.98 10.55 24.07 6.5Z" />
      </svg>
    </div>
  );
};

export default NegotiatesIcon;
