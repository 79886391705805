/* eslint-disable react/prop-types */
// Mui
import {
  IconButton,
  Modal,
  Box,
} from "@mui/material";
// React
import { useState } from "react";
// Icons
import { RemoveRedEye } from "@mui/icons-material";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  maxWidth: 800,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LogsModal({ content }) {
  const [open, setOpn] = useState(false);
  const handleClose = () => {
    setOpn(false);
  };

  const handleOpen = () => {
    setOpn(true);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <RemoveRedEye />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          {content}
        </Box>
      </Modal>
    </>
  );
}
