// import React, { createContext, useContext, useState } from "react";
// import PropTypes from "prop-types";
// const RtlContext = createContext();

// const RtlProvider = ({ children }) => {
//   const localRtl = JSON.parse(localStorage.getItem("rtl"));

//   const [rtl, setRtl] = useState(localRtl ?? false);
//   if (localRtl) {
//     document.dir = "rtl";
//   } else {
//     document.dir = "ltr";
//   }
//   const changeToRtl = () => {
//     setRtl(true);
//     document.dir = "rtl";
//     localStorage.setItem("rtl", true);
//   };
//   const changeToLtr = () => {
//     setRtl(false);
//     document.dir = "ltr";
//     localStorage.setItem("rtl", false);
//   };
//   return (
//     <RtlContext.Provider value={{ rtl, changeToRtl, changeToLtr }}>
//       {children}
//     </RtlContext.Provider>
//   );
// };
// export const useRtl = () => {
//   return useContext(RtlContext);
// };
// export default RtlProvider;
// RtlProvider.propTypes = {
//   children: PropTypes.node,
// };

// RtlContext.js

// RtlContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const RtlContext = createContext();

const RtlProvider = ({ children }) => {
  const localRtl = JSON.parse(localStorage.getItem("rtl"));
  const [rtl, setRtl] = useState(localRtl ?? false);

  if (rtl) {
    document.documentElement.setAttribute("dir", "rtl");
  } else {
    document.documentElement.setAttribute("dir", "ltr");
  }

  const changeToRtl = () => {
    setRtl(true);
    localStorage.setItem("rtl", true);
  };

  const changeToLtr = () => {
    setRtl(false);
    localStorage.setItem("rtl", false);
  };

  return (
    <RtlContext.Provider value={{ rtl, changeToLtr, changeToRtl }}>
      {children}
    </RtlContext.Provider>
  );
};

export const useRtl = () => useContext(RtlContext);

RtlProvider.propTypes = {
  children: PropTypes.node,
};

export default RtlProvider;
