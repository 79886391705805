import React from "react";

const ReceivedFeedback = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 6H8C6.9 6 6.01 6.9 6.01 8L6 26L10 22H24C25.1 22 26 21.1 26 20V8C26 6.9 25.1 6 24 6ZM24 20H9.17L8 21.17V8H24V20ZM15 16H17V18H15V16ZM15 10H17V14H15V10Z" />
      </svg>
    </div>
  );
};

export default ReceivedFeedback;
