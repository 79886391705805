import React from "react";

const SubscriptionIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 10H24V12H8V10ZM10 6H22V8H10V6ZM24 14H8C6.9 14 6 14.9 6 16V24C6 25.1 6.9 26 8 26H24C25.1 26 26 25.1 26 24V16C26 14.9 25.1 14 24 14ZM24 24H8V16H24V24ZM14 16.73V23.26L20 20L14 16.73Z" />
      </svg>
    </div>
  );
};

export default SubscriptionIcon;
