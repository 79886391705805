import { styled, Box } from "@mui/material";
import { secondaryColor } from "../../../colors/allColors.color";

export const LoginContainer = styled(Box)(({ backgroundPhoto }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "center",
  background: `url(${backgroundPhoto})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

export const RightSide = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px",
  marginInline: "auto",
  maxWidth: "50%",
  width: "600px",
  minWidth: "min-content",
  color: secondaryColor,
  /* From https://css.glass */
  background: " rgba(255, 255, 255, 0.2)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(10px)",

  "&::before ": {
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    borderRadius: "15px",
    border: "4px solid transparent",
    background:
      "linear-gradient(to bottom,#B5C8E0 100%,#FFFFFF00 0%) border-box",
    mask: "linear-gradient(#fff 0 0) padding-box,  linear-gradient(#fff 0 0)",
    maskComposite: "exclude",
  },
}));

// export const LeftSide = styled(Box)(({ backgroundPhoto }) => ({
//   background: `url(${backgroundPhoto})`,
//   backgroundSize: "cover",
//   width: "100%",
// }));

export const FormWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const FieldWrapper = styled(Box)(() => ({
  marginTop: "20px",
}));

export const FieldSMS = styled(Box)(() => ({
  direction: "rtl",
  margin: "1rem auto",
  display: "flex",
  justifyContent: "center",
  ".ReactInputVerificationCode__item": {
    position: "relative",
    color: "#262626",
    fontWeight: "500",
    borderBottom: "1px solid #000",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const ForgottenText = styled(Box)(() => ({
  color: secondaryColor,
  textAlign: "center",
  cursor: "pointer",
}));

export const LoginText = styled("p")(() => ({
  fontSize: "27px",
  lineHeight: "1",
  color: "#FFFFFF",
}));

export const LoginTextContainer = styled(Box)(() => ({
  margin: "0 41px 95px 0",
}));
