import { styled, Box } from "@mui/material";

export const FooterWrapper = styled(Box)(({ direction }) => ({
  width: "100%",
  height: "25px",
  backgroundColor: "white",
  boxShadow:
    "  rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
  textAlign: "center",
  display: "flex",
  position: "fixed",
  bottom: "0px",
  flexDirection: direction,

  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  // color: "#ddd",
  cursor: "pointer",
  "a:hover": {
    color: "#820300 !important",
  },
}));
