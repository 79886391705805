import { Outlet, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import { Main, LayoutWrapper } from "./layoutStyles/layoutStyles.style";
import SidebarMenu from "../components/Sidebar/sideBar.component";
// import TopMenu from "../components/TopMenu/topMenu.component";
import { ThemeProvider } from "@mui/material";
import Theme from "./layoutThemeConfig/layoutThemeConfig.theme";
import Footer from "../components/Footer/footer.component";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useLayoutEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack } from "@mui/material";
import { useSideBar } from "../Context/SideBarProvider";
import { useRtl } from "../Context/RtlProvider";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
const layout = () => {
  const { pathname } = useLocation();
  const dynamicBackground = "#eee";

  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { width } = useSideBar();
  const { rtl } = useRtl();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);
  // useEffect(() => {
  //   localStorage.setItem("lang", "en");
  //   localStorage.getItem("lang");
  //   if (localStorage.getItem("lang") === "en") {
  //     setRtl(false);
  //     localStorage.setItem("rtl", false);
  //   } else {
  //     setRtl(true);
  //     localStorage.setItem("rtl", true);
  //   }
  // }, []);
  const rtlCache = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const ltrCache = createCache({
    key: "mui",
  });
  if (isAuthenticated()) {
    return (
      <CacheProvider value={rtl == true ? rtlCache : ltrCache}>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <LayoutWrapper
            dynamicBackground={dynamicBackground}
            background={pathname == "/login" && "#d5d517"}
          >
            {/* Header */}

            {/* Top menu */}
            {/*<TopMenu rtl={rtl} setWithSidebar={setWithSidebar} />*/}
            {/* Main content */}
            {pathname == "/login" ? (
              <Main
                dynamicBackground={dynamicBackground}
                background="#E5E5E5"
                width={width}
              >
                <Outlet />
              </Main>
            ) : (
              <Stack direction="row-reverse">
                <Main
                  dynamicBackground="#fff"
                  background="#E5E5E5"
                  rtl={rtl}
                  width={width}
                >
                  <Header />
                  <Outlet />
                </Main>
                <SidebarMenu />
              </Stack>
            )}
            {/* Footer */}
            <Footer />
          </LayoutWrapper>
          <ToastContainer rtl={rtl ? true : false} />
        </ThemeProvider>
      </CacheProvider>
    );
  } else {
    navigate("/login");
  }
};

export default layout;
