import { TextField, styled } from "@mui/material";
import { InputsBorderColor } from "../../../../colors/allColors.color";

/**
 * @param {number} width
 */
export const InputText = styled(TextField)(
  ({ width, height, rounded, marginRight, marginLeft, colors }) => ({
    border: `1px solid ${InputsBorderColor}`,
    "& fieldset": {
      border: "none",
    },
    width: `${width}px`,
    padding: "0",
    height: height ? `${height}px` : "40px",
    marginBottom: "8px",
    marginRight: marginRight ? marginRight : "4px",
    marginLeft: marginLeft ? marginLeft : "4px",
    borderRadius: `${rounded ? "20px" : "4px"}`,
    "@media(max-width: 768px)": {
      width: "100%",
    },
    input: {
      color: colors ? colors : undefined,
    },
    div: {
      transform: "translate(0px,2px)",
    },
  })
);
