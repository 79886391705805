// import { useCallback, useEffect, useRef, useState } from "react";
// import { fetchToken, getMessagingObj } from "./firebase";
// import { onMessage } from "firebase/messaging";
// import { toast } from "react-toastify";
// import axiosInstance from "../HelperAxios/axios";

// async function notifyMe() {
//   if (!("Notification" in window)) {
//     // Check if the browser supports notifications
//     console.info("This browser does not support desktop notification");
//   } else if (Notification.permission === "granted") {
//     // Check whether notification permissions have already been granted;
//     // if so, create a notification
//     const token = await fetchToken();
//     console.log("Notification permission granted.");
//     if (!token) {
//       return null;
//     }
//     return token;
//   } else if (Notification.permission !== "denied") {
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       const token = await fetchToken();
//       console.log("Notification permission granted.");
//       if (!token) {
//         return null;
//       }
//       return token;
//     } else {
//       console.log("Notification permission not granted.");
//     }
//   }
//   return null;
// }

// export const useFCM = () => {
//   const [fcmToken, setFcmToken] = useState(null);
//   const retryLoadToken = useRef(0);

//   const loadToken = async () => {
//     if (Notification.permission === "denied") {
//       toast.warning("Please Enable Push notifications via browser settings.");
//       console.info(
//         `%cPlease enable notifications via browser settings.`,
//         "color: red; background: #c7c7c7; padding: 8px; font-size: 20px"
//       );
//       return null;
//     }

//     if (retryLoadToken.current >= 3) {
//       console.info(
//         `%cPush Notifications issue - unable to load token after 3 retries, you can refresh the browser`,
//         "color: green; background: #c7c7c7; padding: 8px; font-size: 20px"
//       );
//       return null;
//     }
//     const token = await notifyMe();

//     if (!token) {
//       retryLoadToken.current += 1;
//       loadToken();
//       return;
//     }

//     setFcmToken(token);
//   };

//   useEffect(() => {
//     if (fcmToken) {
//       axiosInstance
//         .post("user/registerFCM", { token: fcmToken })
//         .then((res) => {
//           console.log(res);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [fcmToken]);

//   const listenerMessage = useCallback(async () => {
//     if (!fcmToken) return null;

//     const messaging = await getMessagingObj();
//     if (!messaging) return null;
//     return onMessage(messaging, (payload) => {
//       if (Notification.permission !== "granted") return;

//       const data = payload?.notification ?? {};
//       // Customize notification here
//       const notificationTitle = data.title ?? "";
//       const notificationOptions = {
//         body: data.body,
//         icon: data.icon,
//       };

//       new Notification(notificationTitle, notificationOptions);
//     });
//   }, [fcmToken]);

//   useEffect(() => {
//     const isIOS =
//       /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
//     if (isIOS) {
//       console.log("Push notifications are disabled on iOS");
//       return;
//     }

//     if ("Notification" in window && Notification.permission === "granted") {
//       loadToken();
//     }
//   }, []);

//   useEffect(() => {
//     let instanceOnMessage;
//     listenerMessage().then((r) => (instanceOnMessage = r));

//     return () => instanceOnMessage?.();
//   }, [listenerMessage]);

//   return { loadToken };
// };

import { useCallback, useEffect, useRef, useState } from "react";
import { fetchToken, getMessagingObj } from "./firebase";
import { onMessage } from "firebase/messaging";
import axiosInstance from "../HelperAxios/axios";
import getCookie from "../Utils/getCookie";

// Add this function to subscribe user to push notifications
const subscribeUserToPush = async () => {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: process.env.REACT_APP_VAPID_KEY
      ? process.env.REACT_APP_VAPID_KEY
      : "", // Replace with your public VAPID key
  });

  // Send subscription to your server
  await fetch("/api/subscribe", {
    method: "POST",
    body: JSON.stringify(subscription),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

async function notifyMe() {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const token = await fetchToken();
    console.log("Notification permission granted.");
    if (!token) {
      return null;
    }
    return token;
  } else if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await fetchToken();
      console.log("Notification permission granted.");
      if (!token) {
        return null;
      }
      return token;
    } else {
      return null;
    }
  }
  return null;
}

export const useFCM = () => {
  const [fcmToken, setFcmToken] = useState(null);
  const [tokenSiraj, setTokenSiraj] = useState(null);
  const retryLoadToken = useRef(0);

  const loadToken = async () => {
    const token = await notifyMe();
    const tokens = getCookie("token-siraj");

    if (!token) {
      return null;
    }

    if (retryLoadToken.current >= 3) {
      console.info(
        `%cPush Notifications issue - unable to load token after 3 retries, you can refresh the browser`,
        "color: green; background: #c7c7c7; padding: 8px; font-size: 20px"
      );
      return null;
    }

    if (!token) {
      retryLoadToken.current += 1;
      loadToken();
      return;
    }

    setFcmToken(token);
    setTokenSiraj(tokens);
  };

  useEffect(() => {
    console.log("inja useFCm hast");
    if (fcmToken) {
      axiosInstance
        .post("user/registerFCM", { token: fcmToken })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fcmToken]);

  const listenerMessage = useCallback(async () => {
    if (!fcmToken) return null;

    const messaging = await getMessagingObj();
    if (!messaging) return null;
    return onMessage(messaging, (payload) => {
      if (Notification.permission !== "granted") return;

      const data = payload?.notification ?? {};
      // Customize notification here
      const notificationTitle = data.title ?? "";
      const notificationOptions = {
        body: data.body,
        icon: data.icon,
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }, [fcmToken]);

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      console.log("Push notifications are disabled on iOS");
      return;
    }

    if ("Notification" in window && Notification.permission === "granted") {
      loadToken();
    }

    // Register the service worker
    if ("serviceWorker" in navigator && "PushManager" in window) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log(
              "ServiceWorker registration successful with scope: ",
              registration.scope
            );
            subscribeUserToPush(); // Subscribe to push notifications after service worker registration
          })
          .catch((error) => {
            console.log("ServiceWorker registration failed: ", error);
          });
      });
    }
  }, []);

  useEffect(() => {
    let instanceOnMessage;
    listenerMessage().then((r) => (instanceOnMessage = r));

    return () => instanceOnMessage?.();
  }, [listenerMessage]);

  return { loadToken };
};
