import { styled, Box } from "@mui/material";

export const UploadContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  gap: "5px",
  // "@media(max-width: 768px)": {
  //   width: "100%",
  // },
}));
