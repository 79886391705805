import { styled, Box } from "@mui/material";
// import { thirdColor } from "../../colors/allColors.color";

export const Main = styled(Box)(
  ({ width, dynamicBackground, background, widthSidebar, rtl }) => ({
    width: `calc(100% - ${width})`,
    // margin: "0 auto",
    // display: "flex",
    minHeight: "100vh",
    // height: `${window.innerHeight - 160}px`,
    // direction: rtl ? "rtl" : "ltr",
    backgroundColor: dynamicBackground ? dynamicBackground : background, //the main color before get's dynamic is 'thirdColor'
    " > div:nth-child(2)": {},
    paddingBottom: "40px",
  })
);

export const LayoutWrapper = styled(Box)(
  ({ background, dynamicBackground }) => ({
    backgroundColor: dynamicBackground ? dynamicBackground : background, //the main color before get's dynamic is 'thirdColor'
  })
);
