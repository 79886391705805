import React from "react";

const RolesIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.4502 15.035L16.9102 11.495L18.3202 10.085L20.4402 12.205L24.6802 7.965L26.0902 9.375L20.4502 15.035ZM14.9102 11.035H5.91016V13.035H14.9102V11.035ZM24.9102 17.445L23.5002 16.035L20.9102 18.625L18.3202 16.035L16.9102 17.445L19.5002 20.035L16.9102 22.625L18.3202 24.035L20.9102 21.445L23.5002 24.035L24.9102 22.625L22.3202 20.035L24.9102 17.445ZM14.9102 19.035H5.91016V21.035H14.9102V19.035Z" />
      </svg>
    </div>
  );
};

export default RolesIcon;
