import StrinLocalized from "../Language/language.lang";

const dataStatic = [
  {
    id: 1,
    name: "name",
    type: "ShortString",
    label: StrinLocalized.Name,
    required: true,
  },
  {
    id: 2,
    name: "email",
    type: "ShortString",
    label: StrinLocalized.Email,
    required: true,
  },
  {
    id: 3,
    name: "tell",
    type: "Tell",
    label: StrinLocalized.PhoneNumber,
    required: true,
  },
  {
    id: 4,
    name: "company_name",
    type: "ShortString",
    label: StrinLocalized.CompanyName,
    required: true,
  },
  {
    id: 5,
    name: "emirate_id",
    type: "ShortString",
    label: StrinLocalized.EmirateID,
    required: true,
  },
  {
    id: 6,
    name: "passport_number",
    type: "ShortString",
    label: StrinLocalized.PassportNumber,
    required: true,
  },
  {
    id: 7,
    name: "type_of_business",
    type: "OneSelect",
    optionsField: [
      {
        id: "real_estate_agency",
        name: "Real Estate Agency",
      },
      {
        id: "property_sales_professional",
        name: "Property Sales Professional",
      },
      {
        id: "agent",
        name: "Agent",
      },
      {
        id: "other",
        name: "Other",
      },
    ],
    label: StrinLocalized.TypeBusiness,
    required: true,
  },
  {
    id: 8,
    name: "number",
    type: "ShortString",
    label: StrinLocalized.NumberIndustry,
    required: true,
  },
  {
    id: 9,
    name: "languages_spoken_proficiently",
    type: "OneSelect",
    optionsField: [
      {
        id: "english",
        name: "English",
      },
      {
        id: "arabic",
        name: "Arabic",
      },
      {
        id: "persian",
        name: "Persian",
      },
      {
        id: "turkish",
        name: "Turkish",
      },
      {
        id: "french",
        name: "French",
      },
      {
        id: "russian",
        name: "Russian",
      },
      {
        id: "other",
        name: "Other",
      },
    ],
    label: StrinLocalized.Language,
    required: true,
  },
  {
    id: 10,
    name: "description",
    type: "ShortString",
    label: StrinLocalized.Description,
    required: true,
  },
  {
    id: 11,
    name: "subject",
    type: "ShortString",
    label: StrinLocalized.Subject,
    required: true,
  },
  {
    id: 12,
    name: "website",
    type: "ShortString",
    label: StrinLocalized.Website,
    required: true,
  },
];

export default dataStatic;
