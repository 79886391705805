// Mui
import { Box, styled } from "@mui/material";

const LogsPageWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  padding: "30px 0",
  gap: "1.5rem",
  gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
}));

export default LogsPageWrapper;
