import React from "react";

const TypesIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.79157 23.4L8.13157 23.96V14.93L5.70157 20.79C5.29157 21.81 5.78157 22.98 6.79157 23.4ZM26.2916 19.7L21.3316 7.73C21.0216 6.98 20.2916 6.52 19.5216 6.5C19.2616 6.5 18.9916 6.54 18.7316 6.65L11.3616 9.7C10.6116 10.01 10.1516 10.73 10.1316 11.5C10.1216 11.77 10.1716 12.04 10.2816 12.3L15.2416 24.27C15.5516 25.03 16.2916 25.49 17.0716 25.5C17.3316 25.5 17.5916 25.45 17.8416 25.35L25.2016 22.3C26.2216 21.88 26.7116 20.71 26.2916 19.7ZM17.0916 23.5L12.1316 11.54L19.4816 8.5H19.4916L24.4416 20.45L17.0916 23.5Z" />
        <path d="M15.2616 13.75C15.8139 13.75 16.2616 13.3023 16.2616 12.75C16.2616 12.1977 15.8139 11.75 15.2616 11.75C14.7093 11.75 14.2616 12.1977 14.2616 12.75C14.2616 13.3023 14.7093 13.75 15.2616 13.75Z" />
        <path d="M10.1416 23.5C10.1416 24.6 11.0416 25.5 12.1416 25.5H13.5916L10.1416 17.16V23.5Z" />
      </svg>
    </div>
  );
};

export default TypesIcon;
