import { FooterWrapper } from "./footerStyles/footer.style";
import PropTypes from "prop-types";

const FormMakerFooter = ({ data }) => {
  return (
    <div
      className="w-full bg-neutral-700 text-sm h-16 flex justify-center items-center  text-white"
      style={{
        direction: "ltr",
      }}
    >
      Designed and developed by
      <a className="mx-1" href="https://akafweb.com/en/">
        Akaf Company
      </a>{" "}
      All rights reserved by
      {" " + data?.subscription?.name}
    </div>
  );
};
FormMakerFooter.propTypes = {
  data: PropTypes.any,
};

export default FormMakerFooter;
