import React from "react";

const VisitIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 22H25V20H7V22ZM7 17H25V15H7V17ZM7 10V12H25V10H7Z" />
      </svg>
    </div>
  );
};

export default VisitIcon;
