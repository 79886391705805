import moment from "moment-timezone";

function convertUTCToLocal(utcDateTime, showTime = false) {
  const timeZone = localStorage.getItem("timeZone");
  if (!utcDateTime) return "-";
  let localDateTime;
  if (timeZone) {
    localDateTime = moment.utc(utcDateTime).tz(timeZone);
  } else {
    localDateTime = moment.utc(utcDateTime).local();
  }
  // Convert UTC to local

  // Format the local date and time
  if (showTime) {
    const formattedDateTime = localDateTime.format("YYYY-MM-DD HH:mm");

    return formattedDateTime;
  } else {
    const formattedDateTime = localDateTime.format("YYYY-MM-DD");

    return formattedDateTime;
  }
}
export default convertUTCToLocal;
