// Mui
import { Paper } from "@mui/material";
// Strings
import strings from "../../Language/language.lang";

export default function LogsNotFound() {
  return (
    <Paper
      variant="outlined" elevation={3}
      sx={{
        width: "100%",
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        my: 3,
      }}
    >
      {strings.NotFound}
    </Paper>
  );
}
