import { FooterWrapper } from "./footerStyles/footer.style";
import { useRtl } from "../../Context/RtlProvider";
import StrinLocalized from "../../Language/language.lang";

const Footer = () => {
  const { rtl } = useRtl();
  console.log(rtl);
  return (
    <FooterWrapper direction={rtl ? "rtl" : "ltr"}>
      <spa>{StrinLocalized.Footer}</spa>
      <a
        href={"https://akafweb.com/en/"}
        target={"_blank"}
        rel={"noreferrer"}
        style={{ margin: "0 0.2rem", textDecoration: "none", color: "#B80000" }}
      >
        {StrinLocalized.AkafWeb}
      </a>
      {/*<img src={LogoAkaf} alt="akaf-logo" />© All Rights Reserved for Akaf*/}
    </FooterWrapper>
  );
};
export default Footer;
