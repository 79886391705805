import React from "react";

const CallmeIcon = () => {
  return (
    <div>
      {/* <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 22H25V20H7V22ZM7 17H25V15H7V17ZM7 10V12H25V10H7Z" />
      </svg> */}
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.175 6.25C8.25 7.3625 8.4375 8.45 8.7375 9.4875L7.2375 10.9875C6.725 9.4875 6.4 7.9 6.2875 6.25H8.175ZM20.5 21.275C21.5625 21.575 22.65 21.7625 23.75 21.8375V23.7C22.1 23.5875 20.5125 23.2625 19 22.7625L20.5 21.275ZM9.375 3.75H5C4.3125 3.75 3.75 4.3125 3.75 5C3.75 16.7375 13.2625 26.25 25 26.25C25.6875 26.25 26.25 25.6875 26.25 25V20.6375C26.25 19.95 25.6875 19.3875 25 19.3875C23.45 19.3875 21.9375 19.1375 20.5375 18.675C20.4125 18.625 20.275 18.6125 20.15 18.6125C19.825 18.6125 19.5125 18.7375 19.2625 18.975L16.5125 21.725C12.975 19.9125 10.075 17.025 8.275 13.4875L11.025 10.7375C11.375 10.3875 11.475 9.9 11.3375 9.4625C10.875 8.0625 10.625 6.5625 10.625 5C10.625 4.3125 10.0625 3.75 9.375 3.75Z" />
      </svg>
    </div>
  );
};

export default CallmeIcon;
