import React from "react";

const TicketsIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 8V20H12V8H24ZM24 6H12C10.9 6 10 6.9 10 8V20C10 21.1 10.9 22 12 22H24C25.1 22 26 21.1 26 20V8C26 6.9 25.1 6 24 6ZM16.47 18L13 14.5L14.4 13.09L16.47 15.17L21.6 10L23 11.41L16.47 18ZM8 10H6V24C6 25.1 6.9 26 8 26H22V24H8V10Z" />
      </svg>
    </div>
  );
};

export default TicketsIcon;
