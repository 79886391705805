import React from "react";

const ContactsIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 8H8C6.9 8 6 8.9 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10C26 8.9 25.1 8 24 8ZM24 22H8V10H24V22ZM8 4H24V6H8V4ZM8 26H24V28H8V26ZM16 16C17.38 16 18.5 14.88 18.5 13.5C18.5 12.12 17.38 11 16 11C14.62 11 13.5 12.12 13.5 13.5C13.5 14.88 14.62 16 16 16ZM16 12.5C16.55 12.5 17 12.95 17 13.5C17 14.05 16.55 14.5 16 14.5C15.45 14.5 15 14.05 15 13.5C15 12.95 15.45 12.5 16 12.5ZM21 19.99C21 17.9 17.69 17 16 17C14.31 17 11 17.9 11 19.99V21H21V19.99ZM12.81 19.5C13.42 18.98 14.84 18.5 16 18.5C17.17 18.5 18.59 18.98 19.2 19.5H12.81Z" />
      </svg>
    </div>
  );
};

export default ContactsIcon;
