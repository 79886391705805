import React from "react";

const SettingIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.5902 17C14.1402 17 14.5902 17.45 14.5902 18C14.5902 18.55 14.1402 19 13.5902 19C13.0402 19 12.5902 18.55 12.5902 18C12.5902 17.45 13.0402 17 13.5902 17ZM13.5902 15C11.9302 15 10.5902 16.34 10.5902 18C10.5902 19.66 11.9302 21 13.5902 21C15.2502 21 16.5902 19.66 16.5902 18C16.5902 16.34 15.2502 15 13.5902 15ZM22.0902 13L23.1802 10.59L25.5902 9.5L23.1802 8.41L22.0902 6L21.0002 8.41L18.5902 9.5L21.0002 10.59L22.0902 13ZM24.8702 16.72L24.0902 15L23.3102 16.72L21.5902 17.5L23.3102 18.28L24.0902 20L24.8702 18.28L26.5902 17.5L24.8702 16.72ZM19.8402 18C19.8402 17.88 19.8402 17.75 19.8302 17.63L21.7702 16.16L19.2702 11.83L17.0302 12.77C16.8302 12.64 16.6102 12.51 16.3902 12.4L16.0902 10H11.0902L10.7902 12.41C10.5702 12.52 10.3602 12.65 10.1502 12.78L7.91016 11.83L5.41016 16.16L7.35016 17.63C7.34016 17.75 7.34016 17.88 7.34016 18C7.34016 18.12 7.34016 18.25 7.35016 18.37L5.41016 19.84L7.91016 24.17L10.1502 23.23C10.3502 23.36 10.5702 23.49 10.7902 23.6L11.0902 26H16.0902L16.3902 23.59C16.6102 23.48 16.8202 23.36 17.0302 23.22L19.2702 24.16L21.7702 19.83L19.8302 18.36C19.8402 18.25 19.8402 18.12 19.8402 18ZM18.4202 21.64L16.6902 20.91C16.1302 21.51 15.3902 21.95 14.5602 22.14L14.3202 24H12.8602L12.6302 22.14C11.8002 21.95 11.0602 21.51 10.5002 20.91L8.77016 21.64L8.04016 20.37L9.53016 19.24C9.41016 18.85 9.35016 18.44 9.35016 18.01C9.35016 17.58 9.41016 17.17 9.53016 16.78L8.04016 15.65L8.77016 14.38L10.5002 15.11C11.0602 14.51 11.8002 14.07 12.6302 13.88L12.8602 12H14.3302L14.5602 13.86C15.3902 14.05 16.1302 14.49 16.6902 15.09L18.4202 14.36L19.1502 15.63L17.6602 16.76C17.7802 17.15 17.8402 17.56 17.8402 17.99C17.8402 18.42 17.7802 18.83 17.6602 19.22L19.1502 20.35L18.4202 21.64Z" />
      </svg>
    </div>
  );
};

export default SettingIcon;
