// import moment from "moment-timezone";
// import momentJalaali from "moment-jalaali";

// // Load Persian settings for moment-jalaali
// momentJalaali.loadPersian({ dialect: "persian-modern" });

// /**
//  * Convert numbers either in string or pure number to Persian format
//  * @param {string | number} number
//  * @returns {string} Converted number to Persian format
//  */
// const convertNumberToPersian = (number) => {
//   return number?.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
// };

// /**
//  * Extract the UTC offset from a time zone string.
//  * @param {string} timeZoneString - The time zone string to extract the UTC offset from.
//  * @returns {string} The extracted UTC offset.
//  */
// const extractUtcOffset = (timeZoneString) => {
//   const match = timeZoneString.match(/\(UTC([+-]\d{2}:\d{2})\)/);
//   return match ? match[1] : "+00:00"; // Default to UTC if no match
// };

// /**
//  * Convert a date to specified time zone and optionally to Persian date format based on language setting.
//  * @param {string | Date} date - The date to be converted.
//  * @param {string} [timeZone] - The time zone for the conversion.
//  * @returns {string} The converted date string.
//  */
// const convertToPersian = (date) => {
//   const language = localStorage.getItem("lang");
//   const timeZoneString =
//     localStorage.getItem("timeZone") ||
//     Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const utcOffset = moment.tz.zone(timeZoneString)
//     ? moment.tz(timeZoneString).format("Z")
//     : extractUtcOffset(timeZoneString);

//   // Apply the extracted UTC offset
//   const convertedDate = moment(date).utcOffset(utcOffset, true).format();

//   // Convert to Persian date and time if conditions are met
//   if (language === "FA") {
//     const persianDate = momentJalaali(convertedDate)
//       .locale("fa")
//       .format("jYYYY/jMM/jDD HH:mm:ss");
//     const [datePart, timePart] = persianDate.split(" ");
//     const [year, month, day] = datePart.split("/").map(convertNumberToPersian);
//     const [hours, minutes, seconds] = timePart
//       .split(":")
//       .map(convertNumberToPersian);

//     return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
//   }

//   // Format date and time as desired (e.g., YYYY-MM-DD HH:mm:ss)
//   const localDate = moment.utc(convertedDate).utcOffset(utcOffset, true);
//   const formattedDate = `${localDate.year()}-${String(
//     localDate.month() + 1
//   ).padStart(2, "0")}-${String(localDate.date()).padStart(2, "0")} ${String(
//     localDate.hours()
//   ).padStart(2, "0")}:${String(localDate.minutes()).padStart(2, "0")}:${String(
//     localDate.seconds()
//   ).padStart(2, "0")}`;

//   return formattedDate;
// };

// export { convertToPersian };

// import moment from "moment-timezone";
// import momentJalaali from "moment-jalaali";

// // Load Persian settings for moment-jalaali
// momentJalaali.loadPersian({ dialect: "persian-modern" });

// /**
//  * Convert numbers either in string or pure number to Persian format
//  * @param {string | number} number
//  * @returns {string} Converted number to Persian format
//  */
// const convertNumberToPersian = (number) => {
//   return number?.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
// };

// /**
//  * Extract the UTC offset from a time zone string.
//  * @param {string} timeZoneString - The time zone string to extract the UTC offset from.
//  * @returns {string} The extracted UTC offset.
//  */
// const extractUtcOffset = (timeZoneString) => {
//   const match = timeZoneString.match(/\(UTC([+-]\d{2}:\d{2})\)/);
//   return match ? match[1] : "+00:00"; // Default to UTC if no match
// };

// /**
//  * Convert a date to specified time zone and optionally to Persian date format based on language setting.
//  * @param {string | Date} date - The date to be converted.
//  * @param {string} [timeZone] - The time zone for the conversion.
//  * @returns {string} The converted date string.
//  */
// const convertToPersian = (date) => {
//   const language = localStorage.getItem("lang");
//   const timeZoneString =
//     localStorage.getItem("timeZone") ||
//     Intl.DateTimeFormat().resolvedOptions().timeZone;

//   console.log("language , timeZoneString", language, timeZoneString);

//   // Check if the time zone is valid and get the UTC offset
//   const utcOffset = moment.tz.zone(timeZoneString)
//     ? moment.tz(timeZoneString).format("Z")
//     : extractUtcOffset(timeZoneString);

//   // Apply the extracted UTC offset to the date
//   const localDate = moment(date).utcOffset(utcOffset, true);

//   // Convert to Persian date and time if conditions are met
//   if (language === "FA") {
//     const persianDate = momentJalaali(localDate)
//       .locale("fa")
//       .format("jYYYY/jMM/jDD HH:mm:ss");
//     const [datePart, timePart] = persianDate.split(" ");
//     const [year, month, day] = datePart.split("/").map(convertNumberToPersian);
//     const [hours, minutes, seconds] = timePart
//       .split(":")
//       .map(convertNumberToPersian);

//     return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
//   }

//   // Format Gregorian date and time (e.g., YYYY-MM-DD HH:mm:ss)
//   const formattedDate = `${localDate.year()}-${String(
//     localDate.month() + 1
//   ).padStart(2, "0")}-${String(localDate.date()).padStart(2, "0")} ${String(
//     localDate.hours()
//   ).padStart(2, "0")}:${String(localDate.minutes()).padStart(2, "0")}:${String(
//     localDate.seconds()
//   ).padStart(2, "0")}`;

//   return formattedDate;
// };

// export { convertToPersian };

// import moment from "moment-timezone";
// import momentJalaali from "moment-jalaali";
// import { useTimeZoneMap } from "../Context/TimeZone";

// // Load Persian settings for moment-jalaali
// momentJalaali.loadPersian({ dialect: "persian-modern" });

// /**
//  * Convert numbers either in string or pure number to Persian format
//  * @param {string | number} number
//  * @returns {string} Converted number to Persian format
//  */
// const convertNumberToPersian = (number) => {
//   return number?.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
// };

// /**
//  * Extract the UTC offset from a time zone string.
//  * @param {string} timeZoneString - The time zone string to extract the UTC offset from.
//  * @returns {string} The extracted UTC offset.
//  */
// const extractUtcOffset = (timeZoneString) => {
//   const match = timeZoneString.match(/\(UTC([+-]\d{2}:\d{2})\)/);
//   return match ? match[1] : "+00:00"; // Default to UTC if no match
// };

// /**
//  * Convert a date to specified time zone and optionally to Persian date format based on language setting.
//  * @param {string | Date} date - The date to be converted.
//  * @param {string} [timeZone] - The time zone for the conversion.
//  * @returns {string} The converted date string.
//  */

// function findUtcValue(array, searchText) {
//   for (let i = 0; i < array.length; i++) {
//     if (array[i].text.includes(searchText)) {
//       return array[i].utc[0]; // Return the first UTC value
//     }
//   }
//   return null; // Return null if no match is found
// }
// const convertToPersian = (date) => {
//   const language = localStorage.getItem("lang");
//   const timeZoneString = localStorage.getItem("timeZone");
//   const { timeZoneMap } = useTimeZoneMap();
//   const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   console.log("language , timeZoneString", language, timeZoneString);

//   // Extract the time zone part (e.g., "Bogota, Lima, Quito") from the string
//   // Map the extracted part to a valid time zone identifier
//   const utc = findUtcValue(timeZoneMap, timeZoneString);
//   const timeZone = timeZoneString && utc ? utc : browserTimeZone;

//   // Check if the time zone is valid and get the UTC offset
//   // const utcOffset = moment.tz.zone(timeZone)
//   //   ? moment.tz(timeZone).format("Z")
//   //   : extractUtcOffset(timeZoneString);

//   // Apply the extracted UTC offset to the date

//   const localDate = moment(date).tz(timeZone);

//   // Convert to Persian date and time if conditions are met
//   if (language === "FA") {
//     const persianDate = momentJalaali(localDate)
//       .locale("fa")
//       .format("jYYYY/jMM/jDD HH:mm:ss");
//     const [datePart, timePart] = persianDate.split(" ");
//     const [year, month, day] = datePart.split("/").map(convertNumberToPersian);
//     const [hours, minutes, seconds] = timePart
//       .split(":")
//       .map(convertNumberToPersian);

//     return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
//   }

//   // Format Gregorian date and time (e.g., YYYY-MM-DD HH:mm:ss)
//   const formattedDate = `${localDate.year()}-${String(
//     localDate.month() + 1
//   ).padStart(2, "0")}-${String(localDate.date()).padStart(2, "0")} ${String(
//     localDate.hours()
//   ).padStart(2, "0")}:${String(localDate.minutes()).padStart(2, "0")}:${String(
//     localDate.seconds()
//   ).padStart(2, "0")}`;

//   return formattedDate;
// };

// export { convertToPersian };

import moment from "moment-timezone";
import momentJalaali from "moment-jalaali";
import { useTimeZoneMap } from "../Context/TimeZone";

// Load Persian settings for moment-jalaali
momentJalaali.loadPersian({ dialect: "persian-modern" });

/**
 * Convert numbers either in string or pure number to Persian format
 * @param {string | number} number
 * @returns {string} Converted number to Persian format
 */
const convertNumberToPersian = (number) => {
  return number?.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
};

/**
 * Extract the UTC offset from a time zone string.
 * @param {string} timeZoneString - The time zone string to extract the UTC offset from.
 * @returns {string} The extracted UTC offset.
 */
const extractUtcOffset = (timeZoneString) => {
  const match = timeZoneString.match(/\(UTC([+-]\d{2}:\d{2})\)/);
  return match ? match[1] : "+00:00"; // Default to UTC if no match
};

/**
 * Convert a date to specified time zone and optionally to Persian date format based on language setting.
 * @param {string | Date} date - The date to be converted.
 * @param {string} [timeZone] - The time zone for the conversion.
 * @param {string} [format] - The format for the output date string.
 * @returns {string} The converted date string.
 */
function findUtcValue(array, searchText) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].text.includes(searchText)) {
      return array[i].utc[0]; // Return the first UTC value
    }
  }
  return null; // Return null if no match is found
}

const convertFormatToPersian = (format) => {
  const formatMap = {
    YYYY: "jYYYY",
    YY: "jYY",
    MM: "jMM",
    DD: "jDD",
    dddd: "dddd", // Persian day names are already in Persian
    ddd: "ddd", // Persian abbreviated day names are already in Persian
    HH: "HH",
    mm: "mm",
    ss: "ss",
  };

  return format.replace(
    /YYYY|YY|MM|DD|dddd|ddd|HH|mm|ss/g,
    (match) => formatMap[match]
  );
};

const convertToPersian = (date, format) => {
  const language = localStorage.getItem("lang");
  const timeZoneString = localStorage.getItem("timeZone");
  const { timeZoneMap } = useTimeZoneMap();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("language , timeZoneString", language, timeZoneString);

  // Extract the time zone part (e.g., "Bogota, Lima, Quito") from the string
  // Map the extracted part to a valid time zone identifier
  const utc = findUtcValue(timeZoneMap, timeZoneString);
  const timeZone = timeZoneString && utc ? utc : browserTimeZone;

  // Apply the extracted UTC offset to the date
  const localDate = moment(date).tz(timeZone);

  // Default format if not provided
  const defaultFormat =
    language === "FA" ? "jYYYY/jMM/jDD HH:mm:ss" : "YYYY-MM-DD HH:mm:ss";
  const dateFormat = format || defaultFormat;

  // Convert format to Persian if language is "FA"
  const persianFormat =
    language === "FA"
      ? format
        ? convertFormatToPersian(dateFormat)
        : defaultFormat
      : dateFormat;

  // Convert to Persian date and time if conditions are met
  if (language === "FA") {
    const persianDate = momentJalaali(localDate)
      .locale("fa")
      .format(persianFormat);
    const parts = persianDate.split(/(\s|:|\/|-)/).map((part) => {
      return /\d/.test(part) ? convertNumberToPersian(part) : part;
    });
    return parts.join("");
  }

  // Format Gregorian date and time
  return localDate.format(dateFormat);
};

export { convertToPersian };
