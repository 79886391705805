import React from "react";

const MessagesIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 8H24V20H9.17L8 21.17V8ZM8 6C6.9 6 6.01 6.9 6.01 8L6 26L10 22H24C25.1 22 26 21.1 26 20V8C26 6.9 25.1 6 24 6H8ZM10 16H22V18H10V16ZM10 13H22V15H10V13ZM10 10H22V12H10V10Z" />
      </svg>
    </div>
  );
};

export default MessagesIcon;
