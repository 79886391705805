import { styled, Box, Container } from "@mui/material";

import { textColor } from "../../colors/allColors.color";

export const PageContainer = styled(Container)(() => ({
  display: "flex",
  justifyContent: "initial",
  flexDirection: "column",
  // width: `80%`,
  // maxWidth: "100%",
  // color: textColor,
  // margin: "1rem 0 2rem 0",
}));

//`${window.innerWidth - 280 - 41}px`

export const PageWrapper = styled(Box)(
  ({ backgroundimage, rtl, padding, margin, maxHeight }) => ({
    // width: "100%"
    // height: "100%",
    // maxHeight: maxHeight ? maxHeight : "90%",
    //height: "100vh",

    padding: padding ? padding : "0 16px",
    overflowX: "hidden",
    backgroundColor: "#FFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: `url(${backgroundimage})`,
    backgroundSize: "cover",
    direction: rtl ? "rtl" : "ltr",
    borderRadius: "8px",
    margin: margin ? margin : undefined,
  })
);
