import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import { getAll } from "../Utils/getAll";
export const TimeZoneContex = createContext();

const TimeZoneProvider = ({ children }) => {
  const [timeZoneMap, setTimeZoneMap] = useState([]);

  const getTimeZones = () => {
    getAll(setTimeZoneMap, `language/time-zone`);
  };

  return (
    <TimeZoneContex.Provider value={{ timeZoneMap, getTimeZones }}>
      {children}
    </TimeZoneContex.Provider>
  );
};
export default TimeZoneProvider;

export const useTimeZoneMap = () => {
  return useContext(TimeZoneContex);
};

TimeZoneProvider.propTypes = {
  children: PropTypes.node,
};
