import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { allRoutes } from "./Routes/routes.route";
import Layout from "./Layout/layout";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import RegisterUser from "./Pages/Login/RegisterUser.page";
import { useLocation } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import RegisterSubscriptionForRole from "./Pages/RegisterSubscription/RegisterSubscriptionForRole";
import { useRtl } from "./Context/RtlProvider";
import SeeFormPage from "./Pages/RegisterForm/SeeForm.page";
const Login = lazy(() => import("./Pages/Login/login.page"));
const ResetPassword = lazy(() => import("./Pages/Login/ResetPassword.page"));
const Forget = lazy(() => import("./Pages/Login/ForgetPassword.page"));
const Loginbyphone = lazy(() => import("./Pages/Login/loginByPhone.page"));
const RegisterPublicSubscription = lazy(() =>
  import("./Pages/RegisterUserGroup/RegisterPublicSubscription.page")
);

const ManageRoute = () => {
  const currentUser = useAuthUser();
  const location = useLocation();
  const { rtl } = useRtl();
  useEffect(() => {
    let title;

    if (currentUser) {
      title = currentUser?.subscription?.name
        ? `${currentUser?.subscription?.name} | Portal`
        : "SuperAdmin | Portal";
    } else {
      title = "Portal";
    }

    document.title = title;
  }, [currentUser, location.pathname]);

  return (
    <div style={{ direction: rtl ? "rtl" : "ltr" }}>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback="">
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/create-subscription/:id/:rtl/:language"
          element={
            <Suspense fallback="">
              <RegisterPublicSubscription />
            </Suspense>
          }
        />
        <Route
          path="/register-user"
          element={
            <Suspense fallback="">
              <RegisterUser />
            </Suspense>
          }
        />
        <Route
          path="/reset-password"
          element={
            <Suspense fallback="">
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/forget-password"
          element={
            <Suspense fallback="">
              <Forget />
            </Suspense>
          }
        />
        <Route
          path="/login-by-phone"
          element={
            <Suspense fallback="">
              <Loginbyphone />
            </Suspense>
          }
        />
        <Route
          path="/see-form/:id/:rtl/:language"
          element={
            <Suspense fallback="">
              <SeeFormPage />
            </Suspense>
          }
        />

        <Route path="/" element={<Layout />}>
          {allRoutes.map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense
                  fallback={
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  }
                >
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>
      </Routes>
    </div>
  );
};

export default ManageRoute;
