import { getApps, initializeApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBpqGMErnTAJN_JOwnMM1DnxOOZPoARvQg",
  authDomain: "almadar-siraj.firebaseapp.com",
  projectId: "almadar-siraj",
  storageBucket: "almadar-siraj.appspot.com",
  messagingSenderId: "143546643016",
  appId: "1:143546643016:web:e6bee730f6b1dfaed2de7d",
  measurementId: "G-7FP7MB3H1K",
};

// Initialize Firebase
export const app = !getApps()?.length
  ? initializeApp(firebaseConfig)
  : getApps()[0];

// Initialize Firebase Cloud Messaging and get a reference to the service
export const getMessagingObj = async () => {
  const supported = await isSupported();
  console.log("is supported fcm? >>", supported);
  if (!supported || typeof window === "undefined") return null;
  return getMessaging(app);
};

export const fetchToken = async () => {
  try {
    const messaging = await getMessagingObj();
    if (messaging) {
      console.log(messaging);
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY
          ? process.env.REACT_APP_VAPID_KEY
          : "",
      });
      return token;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};
