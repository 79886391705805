import React from "react";

const ReservationIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 9V16H13V9H19ZM19 7H13C11.9 7 11 7.9 11 9V18H21V9C21 7.9 20.1 7 19 7ZM26 14H23V17H26V14ZM9 14H6V17H9V14ZM24 19H8V25H10V21H22V25H24V19Z" />
      </svg>
    </div>
  );
};

export default ReservationIcon;
