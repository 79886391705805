import { createTheme } from "@mui/material";
const rtl = localStorage.getItem("rtl");
const lang = localStorage.getItem("lang");
// The theme specifies the color of the components, darkness of the surfaces, level of shadow, appropriate opacity of ink elements, etc.
let fontFamily = [];

if (lang) {
  switch (lang) {
    case "FA":
      fontFamily.push("Vazirmatn");
      break;
    case "AR":
      fontFamily.push("Vazirmatn");
      break;
    case "EN":
      fontFamily.push("DM Sans");
      break;
    case "TR":
      fontFamily.push("DM Sans");
      break;
    default:
      fontFamily.push("sans-serif");
  }
}

const theme = createTheme({
  direction: rtl && rtl == true ? "rtl" : "ltr",
  typography: {
    fontFamily,
    fontWeightBold: 700,
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiPagination: {
      defaultProps: {
        dir: "ltr",
      },
    },
  },
  palette: {
    primary: {
      main: "#701951",
    },
    secondary: {
      main: "#505050",
    },
  },
});

export default theme;
