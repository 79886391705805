import React from "react";

const ContractIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.17 8L22 12.83V24H10V8H17.17ZM18 6H10C8.9 6 8 6.9 8 8V24C8 25.1 8.9 26 10 26H22C23.1 26 24 25.1 24 24V12L18 6ZM16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18ZM20 21.43C20 20.62 19.52 19.9 18.78 19.58C17.93 19.21 16.99 19 16 19C15.01 19 14.07 19.21 13.22 19.58C12.48 19.9 12 20.62 12 21.43V22H20V21.43Z" />
      </svg>
    </div>
  );
};

export default ContractIcon;
