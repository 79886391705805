import React from "react";

const UnitsIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 9H8C6.9 9 6 9.9 6 11V21C6 22.1 6.9 23 8 23H24C25.1 23 26 22.1 26 21V11C26 9.9 25.1 9 24 9ZM24 15H18V11H24V15ZM8 11H16V21H8V11ZM18 21V17H24V21H18ZM13.5 16C13.5 16.83 12.83 17.5 12 17.5C11.17 17.5 10.5 16.83 10.5 16C10.5 15.17 11.17 14.5 12 14.5C12.83 14.5 13.5 15.17 13.5 16Z" />
      </svg>
    </div>
  );
};

export default UnitsIcon;
