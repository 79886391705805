import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Proptypes, { string } from "prop-types";
import moment from "moment-jalaali";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";

const DateMange = ({ onChange, value, label }) => {
  moment.loadPersian({ dialect: "persian-modern" });
  const language = localStorage.getItem("lang");
  return (
    <>
      {language == "FA" ? (
        <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={["DatePicker"]}
          >
            <DateTimePicker
              sx={{ width: "100%" }}
              slotProps={{ textField: { size: "small" } }}
              label={label}
              value={value ? moment(value) : undefined}
              onChange={onChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={["DatePicker"]}
          >
            <DatePicker
              sx={{ width: "100%" }}
              slotProps={{ textField: { size: "small" } }}
              value={value ? dayjs(value) : undefined}
              onChange={onChange}
              label={label}
            />
          </DemoContainer>
        </LocalizationProvider>
      )}
    </>
  );
};

export default DateMange;

DateMange.propTypes = {
  onChange: Proptypes.func,
  value: Proptypes.string,
  label: Proptypes.string,
  onBlur: Proptypes.boolean,
};
