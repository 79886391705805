import React from "react";

const ExtraFieldsIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 12H18V15H15V17H18V20H20V17H23V15H20V12ZM6 16C6 13.21 7.64 10.8 10.01 9.68V7.52C6.52 8.76 4 12.09 4 16C4 19.91 6.52 23.24 10.01 24.48V22.32C7.64 21.2 6 18.79 6 16ZM19 7C14.04 7 10 11.04 10 16C10 20.96 14.04 25 19 25C23.96 25 28 20.96 28 16C28 11.04 23.96 7 19 7ZM19 23C15.14 23 12 19.86 12 16C12 12.14 15.14 9 19 9C22.86 9 26 12.14 26 16C26 19.86 22.86 23 19 23Z" />
      </svg>
    </div>
  );
};

export default ExtraFieldsIcon;
