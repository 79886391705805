import React from "react";

const FeedbackIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 6H8C6.9 6 6 6.9 6 8V26L10 22H24C25.1 22 26 21.1 26 20V8C26 6.9 25.1 6 24 6ZM24 20H10L8 22V8H24V20Z" />
      </svg>
    </div>
  );
};

export default FeedbackIcon;
