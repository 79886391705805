import React from "react";

const ContactUsIcon = () => {
  return (
    <div>
      {/* <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 22H25V20H7V22ZM7 17H25V15H7V17ZM7 10V12H25V10H7Z" />
      </svg> */}
      <svg
        fill="white"
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
      >
        <g id="surface1">
          <path d="M 7.359375 1.28125 C 6.128906 1.28125 5.121094 2.289062 5.121094 3.519531 L 5.121094 28.480469 C 5.121094 29.710938 6.128906 30.71875 7.359375 30.71875 L 24.640625 30.71875 C 25.871094 30.71875 26.878906 29.710938 26.878906 28.480469 L 26.878906 9.335938 L 18.824219 1.28125 Z M 7.359375 2.558594 L 17.921875 2.558594 L 17.921875 8 C 17.921875 9.230469 18.929688 10.238281 20.160156 10.238281 L 25.601562 10.238281 L 25.601562 28.480469 C 25.601562 29.019531 25.179688 29.441406 24.640625 29.441406 L 7.359375 29.441406 C 6.820312 29.441406 6.398438 29.019531 6.398438 28.480469 L 6.398438 3.519531 C 6.398438 2.980469 6.820312 2.558594 7.359375 2.558594 Z M 19.199219 3.464844 L 24.695312 8.960938 L 20.160156 8.960938 C 19.621094 8.960938 19.199219 8.539062 19.199219 8 Z M 11.839844 15.679688 C 11.308594 15.679688 10.878906 16.109375 10.878906 16.640625 C 10.878906 17.171875 11.308594 17.601562 11.839844 17.601562 C 12.371094 17.601562 12.800781 17.171875 12.800781 16.640625 C 12.800781 16.109375 12.371094 15.679688 11.839844 15.679688 Z M 14.078125 16 L 14.078125 17.28125 L 21.121094 17.28125 L 21.121094 16 Z M 11.839844 18.878906 C 11.308594 18.878906 10.878906 19.308594 10.878906 19.839844 C 10.878906 20.371094 11.308594 20.800781 11.839844 20.800781 C 12.371094 20.800781 12.800781 20.371094 12.800781 19.839844 C 12.800781 19.308594 12.371094 18.878906 11.839844 18.878906 Z M 14.078125 19.199219 L 14.078125 20.480469 L 21.121094 20.480469 L 21.121094 19.199219 Z M 11.839844 22.078125 C 11.308594 22.078125 10.878906 22.511719 10.878906 23.039062 C 10.878906 23.570312 11.308594 24 11.839844 24 C 12.371094 24 12.800781 23.570312 12.800781 23.039062 C 12.800781 22.511719 12.371094 22.078125 11.839844 22.078125 Z M 14.078125 22.398438 L 14.078125 23.679688 L 21.121094 23.679688 L 21.121094 22.398438 Z M 14.078125 22.398438 " />
        </g>
      </svg>
    </div>
  );
};

export default ContactUsIcon;
