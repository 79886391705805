import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
export const searchContext = createContext();
const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  return (
    <searchContext.Provider value={{ search, setSearch }}>
      {children}
    </searchContext.Provider>
  );
};
export const useSearch = () => {
  return useContext(searchContext);
};
export default SearchProvider;
SearchProvider.propTypes = {
  children: PropTypes.node,
};
