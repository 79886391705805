import { styled, Box } from "@mui/material";

export const FooterWrapper = styled(Box)(() => ({
  width: "100%",
  height: "35px",
  backgroundColor: "#404040",
  display: "flex",
  position: "fixed",
  bottom: "0px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "10px",
  color: "#848181",
}));
