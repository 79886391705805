// Component
import { PageContainer } from "../pagesStyles/pages.styles";
import PageHeader from "../../components/PageHeader/pageHeader.component";
import LogsPageWrapper from "../pagesStyles/logsWrapper.styles";
import LogsCard from "../../components/Logs/LogsCard.component";
import LogsFilter from "../../components/Logs/LogsFilter.component";
import LogsModal from "../../components/Logs/LogsModal.component";
import LogsNotFound from "../../components/Logs/LogsNotFound.component";
import LogsLoading from "../../components/Logs/LogsLoading.component";
// Language
import strings from "../../Language/language.lang";
// React
import { useEffect, useState } from "react";
// Utils
import { getAll } from "../../Utils/getAll";
import CheckPermission from "../../Utils/checkPermission";
// Mui
import { Pagination, PaginationItem } from "@mui/material";
// Icon
import { ArrowForward, ArrowBack } from "@mui/icons-material";
// React Router
import { useSearchParams, useNavigate } from "react-router-dom";
// Utils
import convertUTCToLocal from "../../Utils/convertUTCToLocal";
// Mui
import {
  ImageList,
  ImageListItem,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
// Toast
import { toast } from "react-toastify";

const constOptions = {
  occupation: [
    { value: "occupied_by_owner", label: strings.OccupiedByOwner },
    {
      value: "occupied_by_renter",
      label: strings.OccupiedByRenter,
    },
    { value: "empty", label: strings.Empty },
  ],
  view: [
    { value: "miracle_garden", label: strings.MiracleGarden },
    {
      value: "sheikh_mohd_bin_zayed_rd.",
      label: strings.SheikhMohdBinZayedRd,
    },
    { value: "construction_site", label: strings.ConstructionSite },
  ],
  type: [
    { value: "deluxe_studio", label: strings.DeluxeStudio },
    {
      value: "executive_1_bedroom",
      label: strings.Executive1Bedroom,
    },
    { value: "sanctuary_1_bedroom", label: strings.Sanctuary1Bedroom },
    { value: "1_bedroom", label: `1 ${strings.Bedroom}` },
    { value: "2_bedroom", label: `2 ${strings.Bedroom}` },
    { value: "3_bedroom", label: `3 ${strings.Bedroom}` },
  ],
};

// Get label via API fields
const dictionary = {
  id: strings.ID,
  name: strings.Name,
  reserveType: strings.ReservationType,
  price_sq_m: strings.PSqm,
  price_sq_ft: strings.PSqFt,
  sq_m: strings.Sqm,
  sq_ft: strings.SqFt,
  status: strings.Status,
  price: strings.Price,
  occupation: strings.OccupationStatus,
  type: strings.Type,
  view: strings.View,
  balcony: strings.Balcony,
  study_maid: strings.StudyMaid,
  store_room: strings.StoreRoom,
  files: strings.File,
  featured: strings.Feature,
  floor: strings.Floor,
  room: strings.Room,
  project: strings.Project,
  subscription: strings.Subscription,
  deleted: strings.Delete,
};

export default function UnitLogList() {
  const [logs, setLogs] = useState();

  const [loading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  // Get default search params
  const getSearchParams = () => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const user = searchParams.get("user");
    const page = searchParams.get("page");
    const limit = searchParams.get("limit");

    return {
      from,
      to,
      user,
      page,
      limit,
    };
  };

  const { page, limit } = getSearchParams();

  // Generate search params
  const generateSearchParams = ({ from, to, user, page, limit }) => {
    if (from) {
      setSearchParams((params) => {
        params.set("from", convertUTCToLocal(from._d));
        return params;
      });
    }
    if (to) {
      setSearchParams((params) => {
        params.set("to", convertUTCToLocal(to._d));
        return params;
      });
    }
    if (user) {
      setSearchParams((params) => {
        params.set("user", JSON.stringify(user));
        return params;
      });
    }
    if (page) {
      setSearchParams((params) => {
        params.set("page", page);
        return params;
      });
    }

    if (limit) {
      setSearchParams((params) => {
        params.set("limit", limit);
        return params;
      });
    }
  };

  // Reset search params
  const resetSearchParams = () => {
    setSearchParams((params) => {
      params.delete("from");
      return params;
    });

    setSearchParams((params) => {
      params.delete("to");
      return params;
    });

    setSearchParams((params) => {
      params.delete("user");
      return params;
    });

    setSearchParams((params) => {
      params.delete("page");
      return params;
    });
  };

  // Generate search params according filter state
  const generateUrl = () => {
    const { from, page, to, user, limit } = getSearchParams();

    let baseEndPoint = `unit/list/logs?page=${page ?? 1}&limit=${limit ?? 10}`;

    if (from) {
      baseEndPoint += `&where[date_from]=${from}`;
    }

    if (to) {
      baseEndPoint += `&where[date_to]=${to}`;
    }

    if (user) {
      const { id } = JSON.parse(user);
      baseEndPoint += `&where[user]=${id}`;
    }

    return baseEndPoint;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const endPoint = generateUrl();
    const response = await getAll(setLogs, endPoint);
    setIsLoading(false);
    if (response?.status !== 200) {
      setIsLoading(false);
      setLogs();
      const message = response?.data?.message;
      if (typeof message === "string") {
        toast.error(message);
      } else {
        toast.error(message[0]);
      }
    }
  };

  const search = (data) => {
    generateSearchParams({ ...data, page: 1 });
    fetchData();
  };

  const reset = () => {
    resetSearchParams();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!CheckPermission("list:unitchanges")) {
      navigate("/");
    }
  }, []);

  const pageHandler = (_, value) => {
    generateSearchParams({ page: value });
    fetchData();
  };

  const limitHandler = (event) => {
    generateSearchParams({ limit: event.target.value });
    fetchData();
  };

  const pageCount = Math.ceil(logs?.count / (limit ?? 10));

  const conditionalRender = (fieldName, value) => {
    let dom;

    switch (fieldName) {
      case "files": {
        const content = (
          <ImageList
            sx={{ width: "100%", height: 300 }}
            rowHeight={164}
            cols={3}
          >
            {value.map((photo) => (
              <ImageListItem key={photo}>
                <img src={photo} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        );
        dom = <LogsModal content={content} />;
        break;
      }
      case "occupation": {
        const { label } = constOptions.occupation.find(
          (item) => item.value === value
        );

        dom = (
          <Typography fontSize="15px" fontWeight="fontWeightLight">
            {label.length > 15 ? (
              <LogsModal
                content={
                  <Typography variant="h6" textAlign="center">
                    {label}
                  </Typography>
                }
              />
            ) : (
              label
            )}
          </Typography>
        );

        break;
      }
      case "view": {
        const { label } = constOptions.view.find(
          (item) => item.value === value
        );
        dom = (
          <Typography fontSize="15px" fontWeight="fontWeightLight">
            {label.length > 15 ? (
              <LogsModal
                content={
                  <Typography variant="h6" textAlign="center">
                    {label}
                  </Typography>
                }
              />
            ) : (
              label
            )}
          </Typography>
        );

        break;
      }
      case "type": {
        const { label } = constOptions.type.find(
          (item) => item.value === value
        );
        dom = (
          <Typography fontSize="15px" fontWeight="fontWeightLight">
            {label.length > 15 ? (
              <LogsModal
                content={
                  <Typography variant="h6" textAlign="center">
                    {label}
                  </Typography>
                }
              />
            ) : (
              label
            )}
          </Typography>
        );

        break;
      }
      default: {
        dom = (
          <Typography fontSize="15px" fontWeight="fontWeightLight">
            {typeof value === "boolean"
              ? value
                ? strings.Yes
                : strings.No
              : value}
          </Typography>
        );
        break;
      }
    }

    return dom;
  };

  return (
    <>
      <PageHeader title={strings.UnitsLog} />
      <PageContainer maxWidth="xlg">
        <LogsFilter search={search} reset={reset} />
        {!loading ? (
          logs ? (
            <LogsPageWrapper>
              {logs?.data?.map((log) => (
                <LogsCard
                  data={log}
                  conditionalRender={conditionalRender}
                  dictionary={dictionary}
                  key={log.id}
                  cardName={log?.unit?.name}
                  redirectUrl={`/see-unit/${log?.ID}`}
                />
              ))}
            </LogsPageWrapper>
          ) : (
            <LogsNotFound />
          )
        ) : (
          <LogsLoading />
        )}
        <Stack
          gap={2}
          display="inline-flex"
          direction="row"
          alignItems="center"
        >
          <Pagination
            count={pageCount}
            onChange={pageHandler}
            page={+page || 1}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
          />
          <Select
            size="small"
            sx={{ width: 100 }}
            value={+limit || 10}
            onChange={limitHandler}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Stack>
      </PageContainer>
    </>
  );
}
