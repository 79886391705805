import React from "react";

const UsersIcon = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.39 19.06C20.71 18.2 18.53 17.5 16 17.5C13.47 17.5 11.29 18.2 9.61 19.06C8.61 19.57 8 20.6 8 21.72V24.5H24V21.72C24 20.6 23.39 19.57 22.39 19.06ZM22 22.5H10V21.72C10 21.34 10.2 21 10.52 20.84C11.71 20.23 13.63 19.5 16 19.5C18.37 19.5 20.29 20.23 21.48 20.84C21.8 21 22 21.34 22 21.72V22.5Z" />
        <path d="M16 16.5C18.21 16.5 20 14.71 20 12.5C20 11.13 20 9 20 9C20 8.17 19.33 7.5 18.5 7.5C17.98 7.5 17.52 7.77 17.25 8.17C16.98 7.77 16.52 7.5 16 7.5C15.48 7.5 15.02 7.77 14.75 8.17C14.48 7.77 14.02 7.5 13.5 7.5C12.67 7.5 12 8.17 12 9C12 9 12 11.12 12 12.5C12 14.71 13.79 16.5 16 16.5ZM14 10H18V12.5C18 13.6 17.1 14.5 16 14.5C14.9 14.5 14 13.6 14 12.5V10Z" />
      </svg>
    </div>
  );
};

export default UsersIcon;
