/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
const FormRow = ({ children }) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
      {children}
    </Box>
  );
};

export default FormRow;
