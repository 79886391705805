/* eslint-disable react/prop-types */
// Mui
import {
  Card,
  CardContent,
  Avatar,
  CardHeader,
  Stack,
  Box,
  Typography,
  Divider,
} from "@mui/material";
// Language
import strings from "../../Language/language.lang";
// Component
import LogsModal from "./LogsModal.component";
// Utils
import { convertToPersian } from "../../Utils/ConvertTime";
// React dom
import { Link } from "react-router-dom";

export default function LogsCard({
  data,
  dictionary,
  conditionalRender,
  cardName,
  redirectUrl,
}) {
  return (
    <Card variant="outlined">
      <CardHeader
        avatar={<Avatar>{data?.user_data ? data?.user_data?.username.charAt(0) : data?.user?.username.charAt(0)}</Avatar>}
        title={data?.user_data ? data?.user_data?.name :  data?.user?.name}
        subheader={`${data?.user_data ? data?.user_data?.username : data?.user?.name} ${convertToPersian(
          data.createdAt,
          "YYYY-MM-DD HH:mm:ss"
        )}`}
      />
      <CardContent>
        <Link to={redirectUrl}>
          <Typography
            fontSize="15px"
            fontWeight="fontWeightRegular"
            padding="0.2rem"
            textAlign="center"
            mb={3}
          >
            {`${cardName ?? strings.Deleted} - ${strings.ID}:${data.id}`}
          </Typography>
        </Link>
        <Stack width="100%" justifyContent="center" direction="row">
          <Box flex={1}>
            <Typography
              fontSize="15px"
              fontWeight="fontWeightMedium"
              padding="0.2rem"
              textAlign="center"
            >
              {strings.Before}
            </Typography>
            <Divider />
            <Stack direction="column" spacing={2} mt={2} marginX={2}>
              {Object.keys(data.before).map((log) => (
                <Box display="flex" alignItems="center" key={log.id}>
                  <Typography
                    fontSize="15px"
                    fontWeight="fontWeightLight"
                    color="#656565"
                    mr="0.4rem"
                  >
                    {log === "extra_field" ? (
                      <></>
                    ) : (
                      dictionary[log]
                    )}
                  </Typography>
                  <Typography fontSize="15px" fontWeight="fontWeightLight">
                    {conditionalRender(log, data.before[log])}
                  </Typography>
                </Box>
              ))}
              {data.beforeExtraField?.length ? (
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize="15px"
                    fontWeight="fontWeightLight"
                    color="#656565"
                    mr="0.4rem"
                  >
                    {strings.ExtraField}
                  </Typography>
                  <Typography fontSize="15px" fontWeight="fontWeightLight">
                    {renderExtraField(data.beforeExtraField)}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1}>
            <Typography
              fontSize="15px"
              fontWeight="fontWeightMedium"
              padding="0.2rem"
              textAlign="center"
            >
              {strings.After}
            </Typography>
            <Divider />
            <Stack direction="column" spacing={2} mt={2} marginX={2}>
              {Object.keys(data.after).map((log) => (
                <Box display="flex" alignItems="center" key={log.id}>
                  <Typography
                    fontSize="15px"
                    fontWeight="fontWeightLight"
                    color="#656565"
                    mr="0.4rem"
                  >
                    {log === "extra_field" ? (
                      <></>
                    ) : (
                      dictionary[log]
                    )}
                  </Typography>
                  <Typography fontSize="15px" fontWeight="fontWeightLight">
                    {conditionalRender(log, data.after[log])}
                  </Typography>
                </Box>
              ))}
              {data.afterExtraField?.length ? (
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize="15px"
                    fontWeight="fontWeightLight"
                    color="#656565"
                    mr="0.4rem"
                  >
                    {strings.ExtraField}
                  </Typography>
                  <Typography fontSize="15px" fontWeight="fontWeightLight">
                    {renderExtraField(data.afterExtraField)}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

// Render ui according to Logs API filed type
// function conditionalRender(fieldName, value) {
//   let dom = null;

//   switch (fieldName) {
//     case "files": {
//       const content = (
//         <ImageList sx={{ width: "100%", height: 300 }} rowHeight={164} cols={3}>
//           {value.map((photo) => (
//             <ImageListItem key={photo}>
//               <img src={photo} loading="lazy" />
//             </ImageListItem>
//           ))}
//         </ImageList>
//       );
//       dom = <LogsModal content={content} />;
//       break;
//     }
//     case "occupation": {
//       const { label } = constOptions.occupation.find(
//         (item) => item.value === value
//       );
//       const content = (
//         <Typography variant="h6" textAlign="center">
//           {label}
//         </Typography>
//       );
//       dom = (
//         <Typography fontSize="15px" fontWeight="fontWeightLight">
//           {label.length > 15 ? <LogsModal content={content} /> : label}
//         </Typography>
//       );

//       break;
//     }
//     case "view": {
//       const { label } = constOptions.view.find((item) => item.value === value);
//       const content = (
//         <Typography variant="h6" textAlign="center">
//           {label}
//         </Typography>
//       );
//       dom = (
//         <Typography fontSize="15px" fontWeight="fontWeightLight">
//           {label.length > 15 ? <LogsModal content={content} /> : label}
//         </Typography>
//       );

//       break;
//     }
//     case "agent": {
//       dom = <></>;
//       break;
//     }
//     // Manage different type value
//     case "type": {
//       if (typeof value === "string") {
//         const { label } = constOptions.type.find(
//           (item) => item.value === value
//         );
//         dom = (
//           <Typography fontSize="15px" fontWeight="fontWeightLight">
//             {label.length > 15 ? <LogsModal content={label} /> : label}
//           </Typography>
//         );
//       } else {
//         const label = value?.typeName;

//         if (!label) {
//           dom = (
//             <Typography fontSize="15px" fontWeight="fontWeightLight">
//               {strings.Nullable}
//             </Typography>
//           );
//           break;
//         }

//         dom = (
//           <Typography fontSize="15px" fontWeight="fontWeightLight">
//             {label > 15 ? (
//               <LogsModal content={value?.typeName} />
//             ) : (
//               value?.typeName
//             )}
//           </Typography>
//         );
//       }

//       break;
//     }
//     case "email": {
//       dom = (
//         <Typography fontSize="15px" fontWeight="fontWeightLight">
//           {value.length > 15 ? <LogsModal content={value} /> : value}
//         </Typography>
//       );
//       break;
//     }
//     default: {
//       dom = (
//         <Typography fontSize="15px" fontWeight="fontWeightLight">
//           {typeof value === "boolean"
//             ? value
//               ? strings.Yes
//               : strings.No
//             : value}
//         </Typography>
//       );
//       break;
//     }
//   }

//   return dom;
// }

const renderExtraField = (extraField) => {
  const content = extraField.map((field) => (
    <Box display="flex" alignItems="center" key={field.value}>
      <Typography fontWeight="fontWeightBold" color="#656565" mr="0.4rem">
        {field.extraField.name}
      </Typography>
      <Typography fontWeight="fontWeightLight">{field.value}</Typography>
    </Box>
  ));

  return <LogsModal content={content} />;
};
